import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { StaticQuery, graphql } from 'gatsby';
import parse from 'url-parse';
import { split } from 'lodash';
import { Link } from 'gatsby';
import { Location } from '@reach/router';

import { Container, Chunk, Title, List, ListItem } from './menu.css';
import Logo from 'images/ndc-logo.svg';
import Close from 'images/menu-close.svg';
import Open from 'images/menu-open.svg';

const sidebar = {
  open: {
    x: '0%',
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
  closed: {
    x: '-100%',
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const background = {
  open: {
    display: 'block',
    opacity: 0.5,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
    transitionEnd: {
      display: 'none',
    },
  },
};

const urlParse = input => {
  const url = parse(input, true);
  if (url.host === 'www.ndc-md.org' || url.host === 'ndc-md.org') {
    return url.pathname.replace(/\/+$/, '') || '/';
  }
  return;
};

class Menu extends PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = ({ key }) => {
    if (key === 'Escape') {
      this.props.menuOpen && this.props.hideMenu();
    }
  };

  disableScrolling(menuOpen) {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
      document.documentElement.style.overflow = null;
    }
  }

  render() {
    const { data, menuOpen, menuColor, showMenu, hideMenu } = this.props;
    const { menuChunks } = data.contentfulMenu;

    if (typeof document !== 'undefined') {
      this.disableScrolling(menuOpen);
    }

    return (
      <>
        <div
          className={`flex items-center ${
            menuOpen ? 'fixed' : 'absolute'
          } sm:fixed mt-4 z-50`}
        >
          <button
            className="fixed sm:static flex flex-col items-center justify-center mx-2 xs:mx-4 w-12 text-md backface"
            onClick={menuOpen ? hideMenu : showMenu}
          >
            <Close className={`w-8 h-6 menu-black ${!menuOpen && 'hidden'}`} />
            <Open
              className={`w-8 h-6 menu-${menuColor || 'black'} ${menuOpen &&
                'hidden'}`}
            />
            <span
              className={`mt-3 xs:mt-4 uppercase font-micro font-bold text-xxs xs:text-xs ${
                menuOpen ? 'text-black' : `text-${menuColor || 'black'}`
              }`}
            >
              {menuOpen ? 'close' : 'menu'}
            </span>
          </button>

          <Location>
            {({ location }) => (
              <Link
                to="/"
                className={`transition duration-200 ease-in-out opacity-0 pointer-events-none
                  ${(location.pathname !== '/' || menuOpen) &&
                    'opacity-100 pointer-events-auto'}
                `}
              >
                <Logo
                  className={`max-w-3xs xs:max-w-2xs ml-16 xs:ml-20 sm:ml-0 w-full menu-${
                    menuOpen ? 'black' : menuColor
                  }`}
                />
              </Link>
            )}
          </Location>
        </div>

        <motion.div
          initial={'closed'}
          animate={menuOpen ? 'open' : 'closed'}
          variants={background}
          onClick={hideMenu}
          className="fixed z-40 top-0 right-0 bottom-0 left-0 bg-white opacity-0"
        />

        <motion.div
          initial={'closed'}
          animate={menuOpen ? 'open' : 'closed'}
          variants={sidebar}
          className="fixed z-40 menu-loading"
        >
          <Container>
            {/* <Link to="/">
            <Logo style={{ maxWidth: '12rem' }} />
          </Link> */}

            <div>
              {menuChunks.map(({ id, mainLink, listOfLinks }, i) => (
                <Chunk
                  key={id}
                  border={
                    i !== menuChunks.length - 1 && 'border-b sm:border-b-2'
                  }
                >
                  <Title to={urlParse(mainLink.url)}>{mainLink.text}</Title>
                  <List>
                    {listOfLinks?.length > 0 && listOfLinks.map(({ id, text, url }, i) => (
                      <div key={id}>
                        <ListItem
                          to={urlParse(url)}
                          margin={
                            i !== listOfLinks.length - 1 && 'mb-2p5 xs:mb-3'
                          }
                        >
                          {text}
                        </ListItem>
                      </div>
                    ))}
                  </List>
                </Chunk>
              ))}
            </div>
          </Container>
        </motion.div>
      </>
    );
  }
}

const MenuWithQuery = props => (
  <StaticQuery
    query={graphql`
      query MenuQuery {
        contentfulMenu {
          id
          menuChunks {
            id
            mainLink {
              id
              text
              url
            }
            listOfLinks {
              id
              text
              url
            }
          }
        }
      }
    `}
    render={data => <Menu data={data} {...props} />}
  />
);

Menu.propTypes = {
  data: PropTypes.object,
  menuOpen: PropTypes.bool.isRequired,
  hideMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.func.isRequired,
  menuColor: PropTypes.string,
};

export default MenuWithQuery;
