import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import Menu from 'components/menu';

const MenuContainer = ({ links }) => (
  <Consumer>
    {({ menuOpen, showMenu, menuColor, hideMenu }) => (
      <Menu
        links={links}
        menuOpen={menuOpen}
        menuColor={'black'}
        showMenu={showMenu}
        hideMenu={hideMenu}
      />
    )}
  </Consumer>
);

MenuContainer.propTypes = {
  links: PropTypes.array,
};

export default MenuContainer;
