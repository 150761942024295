exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-and-stories-js": () => import("./../../../src/pages/news-and-stories.js" /* webpackChunkName: "component---src-pages-news-and-stories-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-journal-post-js": () => import("./../../../src/templates/journal-post.js" /* webpackChunkName: "component---src-templates-journal-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-project-post-js": () => import("./../../../src/templates/project-post.js" /* webpackChunkName: "component---src-templates-project-post-js" */)
}

