import nanostyled from 'nanostyled';
import { Link } from 'gatsby';

export const Container = nanostyled('nav', {
  base:
    'bg-brand-yellow max-w-full md:max-w-2p5xl w-screen h-screen pt-24 xs:pt-26 sm:pt-32 pb-8 sm-short:pb-4 px-4 xs:px-10 sm:px-20 text-base xs:text-xl sm:text-2xl sm-short:text-xl overflow-y-auto',
});

export const Chunk = nanostyled('div', {
  base:
    'border-white w-full border-solid py-3 xs:py-4 sm:py-8 sm-short:py-4 flex',
  border: 'border-b sm:border-b-2',
});

export const Title = nanostyled(Link, {
  base: 'font-extrabold w-1/3 mr-4 xs:mr-8 hover:text-white focus:text-white',
});

export const List = nanostyled('ul', {
  base: 'w-2/3',
});

export const ListItem = nanostyled(Link, {
  base: 'block hover:text-white focus:text-white',
  margin: 'mb-4',
});

export const Background = nanostyled('div', {
  base: 'fixed top-0 right-0 bottom-0 left-0 bg-white z-50',
});
